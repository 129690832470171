@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@500&family=League+Spartan&family=PT+Sans:wght@700&display=swap");

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
body {
  text-align: center;
  background-color: #f0f8ff;
  padding: 0;
  margin: 0;
  color: #000000;
  font-family: "Cormorant", serif;
}
