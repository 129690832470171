.container {
  .myswiper {
    width: 100% !important;
    height: 100% !important;
    object-fit: fill !important;
    text-align: center !important;
    .swiperslide {
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      img {
        width: 100% !important;
        height: 420px !important;
        object-fit: cover !important;
      }
      span {
        font-size: 18px !important;
        margin: 30px 10px !important;
        color: #25d366;
        text-transform: capitalize;
      }
    }
  }
  h2 {
    color: #44a6c6;
    font-size: 2rem;
    padding-bottom: 20px;
    text-transform: capitalize;
    text-shadow: blue;
  }
}

@media (max-width: 768px) {
  .container {
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 400px !important;
          text-align: center !important;
        }
        span {
          font-size: 15px !important;
          padding-top: 5px !important;
        }
      }
    }
    h2 {
      font-size: 20px;
      padding-bottom: 20px;
    }
  }
  h2 {
    font-size: 1.2rem;
    padding-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .container {
    padding-bottom: 20px;
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 320px !important;
        }
      }
      span {
        font-size: 13px !important;
        padding: 0px !important;
      }
    }
  }
  h2 {
    font-size: 19px;
    padding-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .container {
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 330px !important;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }
  h2 {
    font-size: 18px;
    padding-bottom: 12px;
  }
}
