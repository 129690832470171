.App__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}
.App__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.App__navbar-logo img {
  width: 120px;
}
.App__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.App__navbar-links a {
  margin: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  transition: 0.5s ease;
}
.App__navbar-links a:hover {
  color: #ee4b2b;
  transform: scale(1.1);
}
.App__navbar-smallscreen {
  display: none;
}
.App__navbar-smallscreen-overlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #cacaca;
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;
}
.App__navbar-smallscreen-overlay .overlay__close {
  font-size: 27px;
  color: blue;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.App__navbar-smallscreen-overlay a {
  list-style: none;
  text-decoration: none;
  margin: 10px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}
@media screen and (max-width: 1150px) {
  .App__navbar-links {
    display: none;
  }
  .App__navbar-smallscreen {
    display: flex;
  }
}
@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-logo img {
    width: 120px;
  }
}
