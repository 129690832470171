.app-about {
  padding: 15px;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.app-info {
  align-self: center;
  justify-self: center;
  padding: 10px 1px;
}
.app-info h3 {
  margin: 1rem 0;
  font-size: 22px;
  color: #44a6c6;
  padding: 5px;
  text-align: left;
  font-family: Cormorant;
  font-weight: 550;
}
.app-info h1 {
  font-size: 1.7rem;
  color: #25d366;
  padding: 10px;
  align-items: center;
  font-weight: 600;
  font-family: Cormorant;
}

.app-info ul li {
  margin-bottom: 1rem 0;
  font-size: 21px;
  color: #000;
  align-items: center;
  padding-bottom: 5px;
  font-family: Cormorant;
  line-height: 2.5;
  text-align: left;
  list-style: disc;
  margin-left: 20px;
  margin-left: 18px;
  text-transform: none;
  font-weight: 400;
}
span {
  text-transform: capitalize !important;
}

@media screen and (min-width: 2000px) {
  .app-about {
    display: flex;
  }
}

@media screen and (max-width: 1150px) {
  .app-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .app-info h1 {
    font-size: 2rem;
  }
  .app-info p {
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .app-about {
    display: flex;
    flex-direction: column;
    margin: 5px 15px;
  }
  .app-info > h1 {
    text-align: center;
  }
  .app-info > h3 {
    font-size: 19px;
    font-weight: 500;
    color: #44a6c6;
  }

  .app-info ul li {
    font-size: 20px;
  }
}

@media screen and (max-width: 450px) {
  .app-about {
    display: flex;
    flex-direction: column;
  }
  .app-info h1 {
    font-size: 1.2rem;
  }
  .app-info h3 {
    font-size: 17px;
    color: #44a6c6;
  }

  .app-info ul li {
    font-size: 20px;
  }
}
