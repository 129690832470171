.clients {
  display: flex;
  padding: 5px 2px;
  background-color: #f0f8ff;
  align-items: center;
  justify-content: center;
}
h3 {
  align-items: center;
  padding: 10px;
  font-size: 2rem;
  color: #25d366;
  font-family: papyrus;
}
.card {
  margin: 10px;
  overflow: hidden;
  transition: transform 100ms ease-in;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.07);
}
.card img {
  object-fit: contain;
  padding: 5px;
  width: 100%;
}

@media screen and (max-width: 2000px) {
  .clients {
    display: flex;
    flex-direction: row;
    padding: 5px 2px;
  }
}

@media screen and (max-width: 1150px) {
  .clients {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 1px;
  }
}

@media screen and (max-width: 650px) {
  .clients {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px 1px;
  }
}

@media screen and (max-width: 450px) {
  .clients {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px 2px;
  }
}
