.all {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.mission {
  padding: 10px 10px;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.mission h4 {
  padding: 15px 10px;
  font-size: 22px;
  text-align: center;
  color: #44a6c6;
  font-family: Garamond;
  text-transform: capitalize;
}

.mission p {
  color: var(--color-black);
  font-size: 21px;
  font-family: var(--font-base);
  align-items: center;
  font-weight: 500;
  line-height: 1.4;
}

.mission ol li {
  font-size: 20px;
  color: #000;
  text-align: center;
  font-family: Garamond;
  padding: 5px;
  margin: 3px;
}
.structure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 10px 20px;
  padding-top: 50px;
}
.structure-image {
  width: 90%;
  height: 100%;
}
.structure-image img {
  width: 90%;
  height: 100%;
  object-fit: contain;
}
/* 
@media (max-width: 568px) {
  .all {
    overflow: auto;
  }
  .structure {
    width: 100%;
    height: auto;
    overflow: auto;
    height: 100%;
    padding: 0;
  }
  .structure-image {
    width: 50%;
    text-align: center;
    padding-top: 20px;
    overflow-y: auto;
  }
  .structure-image img {
    text-align: center;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .structure h2 {
    font-size: 20px;
  }
} */
